<template>
<div class="result">
	<div class="result__image" :style="{backgroundColor : backgroundColor}">
		<div class="result__banner" v-if="this.$slots.banner">
			<slot name="banner"></slot>
		</div>
		<slot name="image"></slot>
	</div>
	<div class="result__footer">
		<slot name="footer"></slot>
	</div>

</div>
</template>

<script>
export default {
	name: 'ResultCard',
	props: {
		backgroundColor: {
			type: String,
			default: 'transparent'
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.result {
	text-align: left;
}

.result__image {
	width: 100%;
	position: relative;
	padding-bottom: 100%;

	img {
		position: absolute;
		top: 15px;
		bottom: 15px;
		left: 15px;
		right: 15px;
		width: calc(100% - 30px);
		height: calc(100% - 30px);
		object-fit: contain;
		object-position: center;
	}
}

.result__banner {
	color: var(--primary-color);
	overflow: auto;
	position: absolute;
		top: 15px;
		right: 15px;
		bottom: 15px;
		left: 15px;
}

.result__footer {
	border-top: 1px solid var(--primary-color);
	padding: 10px 15px;
	position: relative;

	&:before {
		content: '';
		width: 0;
		height: 0;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-bottom: 7px solid var(--primary-color);
		position: absolute;
		top: -7px;
		left: 15px;
	}
}
</style>
